import mbankImgUrl from '@/assets/images/banks-logos/mBank.svg';
import milleniumImgUrl from '@/assets/images/banks-logos/MillenniumBank.svg'
import pkoImgUrl from '@/assets/images/banks-logos/PKOBank.svg'
import ingImgUrl from '@/assets/images/banks-logos/INGBank.svg'
import santanderImgUrl from '@/assets/images/banks-logos/Santander.svg'
import pekaoImgUrl from '@/assets/images/banks-logos/PekaoBank.svg'
import veloImgUrl from '@/assets/images/banks-logos/VeloBank.svg'
import aliorImgUrl from '@/assets/images/banks-logos/AliorBank.svg'
import pocztowyImgUrl from '@/assets/images/banks-logos/BankPocztowy.svg'
import bnpImgUrl from '@/assets/images/banks-logos/BNPParibas.svg'
import bosImgUrl from '@/assets/images/banks-logos/BOSBank.svg'
import creditAgricoleImgUrl from '@/assets/images/banks-logos/CreditAgricole.svg'
import inteligoImgUrl from '@/assets/images/banks-logos/inteligo.png'

export default {
    1: {
        name: "Mbank",
        iconUrl: mbankImgUrl
    },
    2: {
        name: "Millennium",
        iconUrl: milleniumImgUrl,
    },
    3: {
        name: "Pko BP",
        iconUrl: pkoImgUrl
    },
    4: {
        name: "Ing",
        iconUrl: ingImgUrl
    },
    5: {
        name: "Santander",
        iconUrl: santanderImgUrl
    },
    6: { 
        name: "Pekao SA", 
        iconUrl: pekaoImgUrl }
    ,
    7: { 
        name: "VeloBank", 
        iconUrl: veloImgUrl 
    },
    8: { 
        name: "Alior bank", 
        iconUrl: aliorImgUrl 
    },
    16: { 
        name: "Pocztowy", 
        iconUrl: pocztowyImgUrl 
    },
    19: { 
        name: "BNP Paribas", 
        iconUrl: bnpImgUrl 
    },
    21: { 
        name: "Bos", 
        iconUrl: bosImgUrl 
    },
    23: { 
        name: "Credit Agricole", 
        iconUrl: creditAgricoleImgUrl 
    },
    30: { 
        name: "Inteligo", 
        iconUrl: inteligoImgUrl 
    },

}