<script setup>
const props = defineProps({
  type: {
    type: String,
    default: ""
  }
})

const emits = defineEmits(['navigationControl']);

onMounted(() => {
  emits('navigationControl', false);
})
</script>

<template>
  <div>
    <div class="rounded shadow-box font-extralight p-6 pb-12">
      <div class="text-xl mb-4">Wstępna decyzja</div>
        <div class="flex gap-2">
          <div>
            <IconCheckGreen class="!w-6 !h-6" />
          </div>
          <div>Potwierdziliśmy poprawność Twoich danych osobowych.</div>
        </div>
      <div class="flex gap-2 mt-2">
        <div>
          <IconCheckGreen class="!w-6 !h-6" :gray="type!=='success'" />
        </div>
        <div v-if="!type">Twoje zamówienie jest aktualnie weryfikowane. Decyzja zazwyczaj zajmuje nam ok. 2 minut. Możesz zamknąć to okno, zawsze będziesz mógł tu wrócić poprzez link wysłany mailem.</div>
        <div v-if="type==='info'">Automatyczna weryfikacja Twojego zamówienia trwa dłużej niż zazwyczaj. Przekazaliśmy sprawę do naszego konsultanta, aby maksymalnie przyspieszyć proces.</div>
        <div v-if="type==='success'">Zweryfikowaliśmy Twoje zamówienie.</div>
      </div>

      <div v-if="!type" class="flex justify-center items-center pt-10 flex-col">
        <EffectBlueSpinner />
        <div class="text-xl font-light">
          Sprawdzamy zdolność leasingową
        </div>
      </div>

      <div v-if="type==='info'" class="flex justify-center items-center pt-10 flex-col">
        <IconNagInfo class="animate-bounce"/>
        <div class="text-xl font-light">
          Potrzebujemy dodatkowych informacji
        </div>
      </div>

      <div v-if="type==='success'" class="flex justify-center items-center pt-10 flex-col">
        <IconNagSuccess />
        <div class="text-xl font-light">
          Wstępna decyzja pozytywna
        </div>
      </div>

    </div>
  </div>
</template>
