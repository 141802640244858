<template>

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="pencil-alt">
            <path id="Vector"
                d="M9.16536 4.16762H4.9987C4.55667 4.16762 4.13275 4.34321 3.82019 4.65577C3.50763 4.96833 3.33203 5.39225 3.33203 5.83428V15.0009C3.33203 15.443 3.50763 15.8669 3.82019 16.1795C4.13275 16.492 4.55667 16.6676 4.9987 16.6676H14.1654C14.6074 16.6676 15.0313 16.492 15.3439 16.1795C15.6564 15.8669 15.832 15.443 15.832 15.0009V10.8343M14.6537 2.98928C14.8074 2.8301 14.9914 2.70313 15.1947 2.61578C15.398 2.52843 15.6167 2.48245 15.838 2.48053C16.0593 2.47861 16.2788 2.52078 16.4836 2.60458C16.6884 2.68838 16.8745 2.81214 17.031 2.96862C17.1875 3.12511 17.3113 3.3112 17.3951 3.51603C17.4789 3.72085 17.521 3.94032 17.5191 4.16162C17.5172 4.38292 17.4712 4.60162 17.3839 4.80496C17.2965 5.0083 17.1695 5.1922 17.0104 5.34595L9.85536 12.5009H7.4987V10.1443L14.6537 2.98928Z"
                stroke="#0A66A8" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>


</template>

<script>
export default {
    name: "PencilAlt"
}
</script>