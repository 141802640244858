<script setup>
const emits = defineEmits(['back', 'isDisabled']);
const editYourDataStep = async () => {
  emits('back', true);
}
</script>

<template>
  <div>
    <div class="rounded shadow-box p-6">
      <div class="text-xl font-extralight mb-4">Potwierdzenie danych</div>
      <div class="text-base font-extralight mb-4">Twoje dane osobowe wpisane w formularzu nie zgadzają się z danymi przekazanymi nam przez Twój bank. Sprawdź wpisane dane i spróbuj ponownie.</div>
      <div class="flex justify-center items-center pt-10 flex-col">
        <IconNagInfo class="animate-bounce"/>
        <button @click="editYourDataStep" class="text-base leading-6 text-azure flex items-center">
          <IconPencilAlt class="mr-1"/> Popraw swoje dane
        </button>
      </div>
    </div>
  </div>
</template>