<script setup>
import NagDataVerification from "~/components/DigitalOrder/NagDataVerification.vue";
import bankListLogos from "~/configs/bankList.js";

const emits = defineEmits(['psd2Confirmed', 'navigationControl', 'isDisabled', 'back']);
const isDisabled = ref(false);
const psd2Status = ref(null);

onMounted(() => {
  isDisabled.value = true;
  emits('isDisabled', true);
  Object.keys(bankListLogos).forEach(key => {
    const index = parseInt(key);
    if (updatedBankList.value[index]) {
      updatedBankList.value[index] = {
        ...updatedBankList.value[index],
        iconUrl: bankListLogos[index].iconUrl !== '' ? bankListLogos[index].iconUrl : updatedBankList.value[index].iconUrl
      };
    }
  })

})


const route = useRoute();
const backend = useBackend();
const uuid = route.params.uuid;
const selectedBankId = ref();
const setBank = async (bankId) => {
  if (bankId) {
    const { data: { redirectUrl = '' } } = await backend.initiatePsd2({ uuid: route.params.uuid, bank_id: bankId });
    await navigateTo(redirectUrl, { external: true })
  }
}

const submit = () => {
  return true;
}
const setErrors = (err) => {
  console.log(err);
}

defineExpose({
  submit, setErrors
})

// await musi być po defineExpose w tym przypadku - inna kolejność powoduje błąd

const updatedBankList = ref();
const { data: { banks } } = await backend.getBankList();
updatedBankList.value = banks;
psd2Status.value = await backend.initiatePsd2Result({ uuid: uuid });
let intervalId = null;

if (psd2Status.value.data.status === 'POSITIVE') {
  emits('psd2Confirmed', true);
}
if (psd2Status.value.data.status === 'PENDING') {
  intervalId = setInterval(async () => {
    psd2Status.value = await backend.initiatePsd2Result({ uuid: uuid });
    if (psd2Status.value.data.status === 'POSITIVE') {
      emits('psd2Confirmed', true);
    }
    if (psd2Status.value.data.status !== 'PENDING') {
      clearInterval(intervalId);
    }
  }, 5000);
}
</script>


<template>
  <div>
    <div v-if="psd2Status.data.status === 'No Autopay UUID, run initiate.'" class="rounded shadow-box px-6 pt-6 pb-12">
      <div class="text-xl font-extralight mb-4">Potwierdzenie danych</div>
      <p class="text-base font-extralight mb-4">Zaloguj się do swojego banku, żeby bezpiecznie potwierdzić swoje dane
        osobowe.</p>
      <div class="grid gap-6 grid-cols-2 sm:grid-cols-3 xl:grid-cols-5">
        <div @click="setBank(index)" v-for="(bank, index) in updatedBankList" :key="bank.uuid"
          :class="index == selectedBankId ? 'shadow-[0px_0px_4px_0px_rgba(0,0,0,0.20),0px_20px_25px_0px_rgba(0,0,0,0.10),0px_10px_10px_0px_rgba(55,65,81,0.50)]' : 'shadow-box'"
          class="hover:shadow-[0px_0px_4px_0px_rgba(0,0,0,0.20),0px_20px_25px_0px_rgba(0,0,0,0.10),0px_10px_10px_0px_rgba(55,65,81,0.50)] h-20 w-full flex items-center rounded-lg justify-center p-4 cursor-pointer">
          <img class="max-h-full max-w-full w-full h-full flex items-center justify-center object-contain"
            :src="bank.iconUrl" :alt="bank.name">
        </div>
      </div>
      <div>
      </div>
    </div>
    <NagDataVerification v-else-if="psd2Status.data.status === 'NEGATIVE'" @back="emits('back', true)" />
    <div v-else-if="psd2Status.data.status === 'PENDING' || psd2Status.data.status === 'POSITIVE'"
      class="rounded shadow-box px-6 pt-6 pb-12">
      <div class="text-xl font-extralight mb-4">Potwierdzenie danych</div>
      <div class="flex justify-center items-center pt-10 flex-col">
        <EffectBlueSpinner />
        <div class="text-xl font-light">
          Weryfikujemy Twoje dane
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>
