<script setup>

const props = defineProps({
  car: Object,
})

const brand = computed(() => props.car ? props.car.brandName : "");
const model = computed(() => props.car ? props.car.modelName : "");
const version = computed(() => props.car ? props.car.versionName : "");

const leasing = useLeasingParams();

const initialFeeValue = computed(() => props.car.price * leasing.getCurrentParams().initialFee / 100)
const repurchaseValue = computed(() => props.car.price * leasing.getCurrentParams().repurchase / 100)

const submit = () => {
  return [];
}

defineExpose({
  submit
})


</script>

<template>
  <div>
    <div class="relative">
      <div class="shadow-box -ml-4 -mr-4 sm:-mx-8 bg-gray-100 container-sized">
        <div class="bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%">
          <div class="px-6 sm:px-8">
            <CarOfferPreview
              :header="`${brand} ${model}`"
              :subheader="version"
              :car="props.car"
              no-button
              no-adjust
            />
          </div>
        </div>
      </div>
    </div>
    <div class="pt-10">
      <div class="shadow-box pt-2 pb-4 px-4 lg:pt-3 lg:pb-6 lg:px-6 mt-12 mb-8  rounded-lg">
        <OfferHeader right="WIBOR 5,86%">Kalkulacja finansowania</OfferHeader>
      <div class="grid grid-cols-7 font-extralight text-xs md:text-sm w-full text-center gap-y-2 py-6 px-6">
        <div class="col-span-3"></div>
        <div class="hidden md:block">udział [%]</div>
        <div class="">ilość rat</div>
        <div class="">netto</div>
        <div class="">brutto</div>

          <div class="text-left col-span-3">wpłata wstępna <span class="inline-block md:hidden">(20%)</span></div>
        <div class="hidden md:block">{{ leasing.getCurrentParams().initialFee }}%</div>
        <div>1</div>
        <div><EffectPrice :value="initialFeeValue" offer-client="entrepreneur"/></div>
        <div><EffectPrice :value="initialFeeValue" offer-client="consumer"/></div>
        <div class="col-span-7 border-b border-b-gray-300"></div>

          <div class="text-left col-span-3">wartość końcowa <span class="inline-block md:hidden">(20%)</span></div>
        <div class="hidden md:block">{{ leasing.getCurrentParams().repurchase }}%</div>
        <div>1</div>
        <div><EffectPrice :value="repurchaseValue" offer-client="entrepreneur"/></div>
        <div><EffectPrice :value="repurchaseValue" offer-client="consumer"/></div>
      </div>
    </div>
      <div class="grid grid-cols-7 font-extralight text-xs md:text-sm w-full text-center gap-y-2 py-6 rounded-lg border-2 border-azure text-azure px-6 items-center">
        <div class="text-left md:text-2xl font-light col-span-3">Twoja rata leasingowa</div>
        <div class="hidden md:block"></div>
        <div>{{ leasing.getCurrentParams().duration }}</div>
        <div :class="{'text-sm md:text-2xl': leasing.getCurrentParams().client === 'entrepreneur'}"><EffectPrice :value="props.car.installment" offer-client="entrepreneur"/></div>
        <div :class="{'text-sm md:text-2xl': leasing.getCurrentParams().client === 'consumer'}"><EffectPrice :value="props.car.installment" offer-client="consumer"/></div>
      </div>

    </div>
  </div>
</template>
