<script setup>
const props = defineProps({
  step: Number
})

const steps = [
  "Wybór samochodu",
  "Dane kontaktowe",
  "Parametry finansowania",
  "Twoja oferta",
  "Dane do wniosku",
  "Potwierdzenie danych",
  "Wstępna decyzja",
  "Podpisanie umowy",
];

const visibleSteps = [
  [1, 1, 1, 0, 0, 0, 0, 1],
  [1, 1, 1, 0, 0, 0, 0, 1],
  [0, 1, 1, 1, 0, 0, 0, 1],
  [0, 0, 1, 1, 1, 0, 0, 1],
  [0, 0, 0, 1, 1, 1, 1, 1],
  [0, 0, 0, 1, 1, 1, 1, 1],
  [0, 0, 0, 1, 1, 1, 1, 1],
  [0, 0, 0, 1, 1, 1, 1, 1],
];

</script>

<template>
  <DigitalOrderSteps>
    <template v-for="(step,index) in steps">
      <DigitalOrderStep
        :class="{'hidden lg:flex': !visibleSteps[props.step - 1][index]}"
        :done="props.step > index + 1"
        :selected="props.step === index + 1"
        :step="index + 1"
        :text="step"
        translucent grayArrow />
      <template v-if="index + 2 === steps.length">
        <DigitalOrderStepPlaceholder :class="['flex lg:hidden',{'!hidden': props.step > 4}]" />
      </template>
    </template>
  </DigitalOrderSteps>
</template>
